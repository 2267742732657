import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  const title = "קטע מספר 2 – תלמי יוסף – גבולות ( דרך השדות )";
  return (
    <Layout path={location.pathname} title={title}>
      <SectionContent title={title}>
        <div>
          <p>
            <strong>אורך הקטע: 9.99 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            רצים לאורך 'שביל השדות' בכיוון כללי צפון-מזרח, מזרח כ- 2.7 ק&quot;מ
            עד לצומת כבישי שדות. פונים ימינה לכיוון כללי דרום-מזרח, מזרח לאורך
            'דרך השדות' כ- 6.5 ק&quot;מ עד לפניה שמאלה (צפונה) למצפה גבולות.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: בחלק הראשון מגמת עליה מתונה. בחלק השני
            מישור.
          </p>
          <p>
            <strong>מסלול</strong>: כביש שדות.
          </p>
          <p>
            <strong>בטיחות</strong>: ריצה בשולי הכביש. זה כביש פנימי המשמש את
            חקלאי האזור.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : ברחבת החניה של מצפה גבולות.
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=duuyxqfgsfwlifkj"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
